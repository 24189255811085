import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { Form, Links } from 'components/Pages/ContactUs/Sections';
import BrandImg from 'images/strike-brand.png';

const ContactUs = () => {
  return (
    <Layout>
      <SEO
        title="Contact Us | We're Here For You | Strike Online Estate Agents"
        description="Got Questions? We've Got Answers. Contact Strike For More Information On How We Can Help You Sell Your Home For Free."
        canonical="/contact-us"
        metaImage={BrandImg}
      />
      <Form />
      <Links />
    </Layout>
  );
};

export default ContactUs;
