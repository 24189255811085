import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SUCCESS, FAILED, useQueryString } from '@strikelabs/vega';
import { Link } from 'gatsby';
import {
  TextInput,
  Button,
  Box,
  Text,
  Loader,
  Select,
  TextArea,
  HideBreakpoint,
  ShowBreakpoint,
} from '@strikelabs/luna';
import { identifier, getFieldErrors, isFieldValid } from '@strikelabs/pax';

import checkEnquiryQuery from 'utils/checkEnquiryQuery';

import {
  RowContainer,
  InputWrapper,
  SelectWrapper,
  FormContainer,
} from '../style';

const ContactForm = ({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  isFormValid,
  setEnquiryType,
  enquiryType,
  loading,
  submissionStatus,
}) => {
  const qs = useQueryString();

  const isDisabled = (() => {
    if (
      isFormValid &&
      values.firstName &&
      values.lastName &&
      values.emailAddress &&
      values.phoneNumber &&
      values.message &&
      enquiryType !== ''
    ) {
      return false;
    } else {
      return true;
    }
  })();

  const enquiryOptions = [
    { value: 'callback', label: 'Callback' },
    { value: 'recruitment', label: 'Recruitment' },
    { value: 'buying', label: 'Buying' },
    { value: 'selling', label: 'Selling' },
    { value: 'mortgages', label: 'Mortgages' },
    { value: 'conveyancing', label: 'Conveyancing' },
    { value: 'moving_services', label: 'Moving Services' },
    { value: 'general', label: 'General' },
  ];

  useEffect(() => {
    const found = checkEnquiryQuery(qs.enquiry, enquiryOptions);
    if (found) {
      setEnquiryType(qs.enquiry);
    }
  }, []);

  return (
    <FormContainer {...identifier({ name: 'contact-form', requireId: false })}>
      {loading && <Loader fullSize />}
      <RowContainer>
        <Box width="100%" mr={['none', 'none', 2, 2]}>
          <InputWrapper>
            <TextInput
              id="firstName"
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="First name"
              error={getFieldErrors({ key: 'firstName', touched, errors })}
              success={isFieldValid({ key: 'firstName', errors, values })}
              value={values.firstName}
            />
          </InputWrapper>
        </Box>
        <Box width="100%">
          <InputWrapper>
            <TextInput
              id="lastName"
              name="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Last name"
              error={getFieldErrors({ key: 'lastName', touched, errors })}
              success={isFieldValid({ key: 'lastName', errors, values })}
              value={values.lastName}
            />
          </InputWrapper>
        </Box>
      </RowContainer>
      <RowContainer>
        <Box width="100%">
          <InputWrapper>
            <TextInput
              id="emailAddress"
              name="emailAddress"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Your email"
              error={getFieldErrors({ key: 'emailAddress', touched, errors })}
              success={isFieldValid({ key: 'emailAddress', errors, values })}
              value={values.emailAddress}
            />
          </InputWrapper>
        </Box>
      </RowContainer>
      <RowContainer>
        <Box width="100%" mr={['none', 'none', 2, 2]}>
          <InputWrapper>
            <TextInput
              id="phoneNumber"
              name="phoneNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Phone number"
              error={getFieldErrors({ key: 'phoneNumber', touched, errors })}
              success={isFieldValid({ key: 'phoneNumber', errors, values })}
              value={values.phoneNumber}
            />
          </InputWrapper>
        </Box>
        <SelectWrapper>
          <Select
            id="enquiry-type"
            value={enquiryType}
            placeholder="Enquiry type"
            options={enquiryOptions}
            onChange={optionValue => setEnquiryType(optionValue)}
          />
        </SelectWrapper>
      </RowContainer>
      <RowContainer>
        <Box width="100%" mt={[2, 1, 1, 1]}>
          <InputWrapper>
            <TextArea
              id="message"
              name="message"
              rows={3}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="How can we help you?"
              error={getFieldErrors({ key: 'message', touched, errors })}
              success={isFieldValid({ key: 'message', errors, values })}
              value={values.message}
            />
          </InputWrapper>
        </Box>
      </RowContainer>
      {enquiryType === 'mortgages' && (
        <Box mt={1}>
          <Text styleType="caption" color="helperText" ext>
            By sending this message you agree for Strike to contact you to
            discuss your estate agency and/or mortgage needs. Our{' '}
            <Link to="/legal/financial-services-privacy-policy">
              privacy policy
            </Link>{' '}
            details how we process your data.
          </Text>
        </Box>
      )}
      {submissionStatus === SUCCESS ? (
        <Box
          mt={4}
          {...identifier({
            name: 'message-sent-confirmation',
            requireId: false,
          })}
        >
          <Text styleType="body" ext>
            Thank you for getting in touch, we&apos;ll be with you soon.
          </Text>
        </Box>
      ) : (
        <>
          <Box mt={4}>
            <ShowBreakpoint breakpoint="smallTablet">
              <Button
                disabled={isDisabled}
                onClick={() => handleSubmit()}
                {...identifier({
                  name: 'send-message-button',
                  requireId: false,
                })}
              >
                Send message
              </Button>
            </ShowBreakpoint>
            <HideBreakpoint breakpoint="smallTablet">
              <Button
                disabled={isDisabled}
                onClick={() => handleSubmit()}
                fullWidth
                {...identifier({
                  name: 'send-message-button',
                  requireId: false,
                })}
              >
                Send message
              </Button>
            </HideBreakpoint>
          </Box>
          {submissionStatus === FAILED && (
            <Box mt={1}>
              <Text styleType="caption" color="statusError" ext>
                Sorry, there was an error processing your request. <br />
                Please try again.
              </Text>
            </Box>
          )}
        </>
      )}
    </FormContainer>
  );
};

ContactForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  setEnquiryType: PropTypes.func.isRequired,
  enquiryType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  submissionStatus: PropTypes.string,
};

export default ContactForm;
