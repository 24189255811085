import { useWebSend } from '@strikelabs/vega';

const useContactUs = () => {
  const { GATSBY_API_URL } = process.env;

  const [detailsState, sendDetails] = useWebSend({
    method: 'POST',
    url: `${GATSBY_API_URL}/contact-us`,
  });

  const postDetails = values => {
    const {
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      enquiryType,
      message,
    } = values;
    sendDetails({
      data: {
        enquiry_type: enquiryType,
        first_name: firstName,
        last_name: lastName,
        email: emailAddress,
        telephone: phoneNumber,
        message,
      },
    });
  };

  return {
    detailsState,
    postDetails,
  };
};

export default useContactUs;
