import styled from 'styled-components';
import { rem } from 'polished';
import { Text } from '@strikelabs/luna';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing.rg};
  padding-bottom: ${({ theme }) => theme.spacing.rg};
  margin-bottom: 0;

  ${({ theme }) => theme.media.desktop} {
    padding-top: ${({ theme }) => theme.spacing.xl};
    padding-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.smallTablet} {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    flex-direction: row;
  }
`;

export const StyledLinkUnderline = styled.a`
  color: ${({ theme }) => theme.colors.bodyText};
`;

export const StyledLink = styled(StyledLinkUnderline)`
  text-decoration: none;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  z-index: 99;
  margin-top: ${rem(19)};

  li {
    font-weight: 400;
    &:hover {
      color: ${({ theme }) => theme.colors.bodyText};
    }
  }
`;

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
  }
`;

export const InfoText = styled(Text).attrs({ as: 'p', ext: true })`
  ${({ theme }) => theme.media.desktop} {
    /*  font-size: ${rem(24)};
    line-height: ${rem(36)}; */
  }
`;
