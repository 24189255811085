import React from 'react';
import { Box, Text, ButtonAnimated, Flex } from '@strikelabs/luna';

import { Container } from 'components/common';
import { Column, Image, LinkTitle } from './style';
import Grass from './images/grass.png';
import Sofa from './images/sofa.png';

const Links = () => {
  return (
    <Container>
      <Box mb={[2, 2, 5]}>
        <Text as="h2" ext>
          How can we help you today?
        </Text>
      </Box>
      <Flex flexDirection={['column', 'column', 'column', 'column', 'row']}>
        <Column left>
          <Image image={Sofa} alt="Family sat on sofa" />
          <Box mt={[3, 3, 5, 5]} mb={3}>
            <LinkTitle>Sell your home for free</LinkTitle>
          </Box>
          <Text styleType="body" ext>
            We&apos;ve teamed up with Purplebricks so even more people can sell
            their homes for free. And it only takes a few clicks to get started.
          </Text>
          <Box mt={4}>
            <ButtonAnimated to="/book-valuation">
              Book a valuation
            </ButtonAnimated>
          </Box>
        </Column>
        <Column>
          <Image image={Grass} alt="Mum and child on grass" />
          <Box mt={[3, 3, 5, 5]} mb={3}>
            <LinkTitle>Fast, straightforward mortgage advice</LinkTitle>
          </Box>
          <Text styleType="body" ext>
            No jargon, no stress. Just great advice when you need it most
            &mdash; access to thousands of mortgage deals.
          </Text>
          <Box mt={4}>
            <ButtonAnimated to="/services/mortgages">
              Get mortgage advice
            </ButtonAnimated>
          </Box>
        </Column>
      </Flex>
    </Container>
  );
};

export default Links;
