import React, { useState, useEffect } from 'react';
import { Text, Flex, Box } from '@strikelabs/luna';
import {
  PHONE_REGEX,
  EMAIL_REGEX,
  SUCCESS,
  FAILED,
  useAuth,
} from '@strikelabs/vega';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import useContactUs from 'hooks/useContactUs';
import ContactForm from './ContactForm';
import { Outer, StyledLink, StyledLinkUnderline, InfoText } from './style';

const Form = () => {
  const [enquiryType, setEnquiryType] = useState('');
  const { detailsState, postDetails } = useContactUs();
  const [submissionStatus, setSubmissionStatus] = useState('');
  const { user } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNumber: user?.phoneNumbers[0]?.phoneNumber || '',
      emailAddress: user?.emailAddresses[0].emailAddress || '',
      message: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required.'),
      lastName: Yup.string().required('Last name is required.'),
      emailAddress: Yup.string()
        .matches(EMAIL_REGEX, 'Enter a valid email.')
        .required('Email is required.'),
      phoneNumber: Yup.string()
        .matches(PHONE_REGEX, 'Enter a valid phone number.')
        .required('Phone number is reqiured.'),
      message: Yup.string().required('Please tell us how we can help.'),
    }),
    onSubmit: async values => {
      const newValues = { ...values, enquiryType };
      await postDetails(newValues);
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isValid,
    resetForm,
  } = formik;

  useEffect(() => {
    if (detailsState.status === SUCCESS) {
      setSubmissionStatus(SUCCESS);
      resetForm({});
      setEnquiryType('');
    }

    if (detailsState.status === FAILED) {
      setSubmissionStatus(FAILED);
    }
  }, [detailsState.status]);

  return (
    <Outer>
      <Box mb={[4, 4, 4, 4, 5]}>
        <Text as="h1" id="hero-heading" ext>
          Get in touch
        </Text>
      </Box>
      <Flex justifyContent="space-between" flexWrap="wrap">
        <ContactForm
          values={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          isFormValid={isValid}
          setEnquiryType={setEnquiryType}
          enquiryType={enquiryType}
          loading={detailsState.loading}
          submissionStatus={submissionStatus}
        />
        <Flex
          flexDirection="column"
          mt={[4, 4, 4, 4, '0']}
          width={[1, 1, 1, 1, '40%']}
        >
          <Text as="h3" ext>
            We&apos;re here for you
          </Text>
          <Box mt={4} mb={3}>
            <InfoText>
              <StyledLink href="mailto:info@strike.co.uk">
                info@strike.co.uk
              </StyledLink>
              <br />
              <StyledLink href="tel:+443331038390">0333 103 8390</StyledLink>
            </InfoText>
          </Box>

          <Box mb={3}>
            <InfoText>
              Weekdays, 9am - 5:30pm <br />
              Saturday, CLOSED <br />
              Sunday, CLOSED
            </InfoText>
          </Box>
          <Box>
            <InfoText>
              Got questions? We&apos;ve got answers. <br />
              Visit our{' '}
              <StyledLinkUnderline href="/selling/faq">
                FAQs
              </StyledLinkUnderline>
              .
            </InfoText>
          </Box>
        </Flex>
      </Flex>
    </Outer>
  );
};

export default Form;
