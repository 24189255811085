import styled from 'styled-components';
import { Text } from '@strikelabs/luna';
import { rem } from 'polished';

export const Container = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  flex-direction: column;
  padding: ${({ theme }) => `0 ${theme.defaults.gutterSpacing}`};

  ${({ theme }) => theme.media.tablet} {
    padding: ${({ theme }) => `0 ${theme.spacing.lg}`};
  }

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
    padding: ${({ theme }) => `0 ${theme.spacing.xl}`};
  }
`;

export const ComponentGutter = styled.div`
  padding: ${({ theme }) => `0 ${theme.defaults.gutterSpacing}`};

  ${({ theme }) => theme.media.giant} {
    padding: 0;
  }
`;

export const ComponentWrapper = styled(Container)`
  margin-bottom: 0;
  padding: ${({ theme }) =>
    `${theme.spacing.md} ${theme.defaults.gutterSpacing}`};

  ${({ theme }) => theme.media.tablet} {
    padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.lg}`};
  }

  ${({ theme }) => theme.media.desktop} {
    padding: ${({ theme }) => `${theme.spacing.lg} ${theme.spacing.xl}`};
  }
`;

export const BlockQuoteWrapper = styled(ComponentWrapper)`
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${({ theme }) => theme.media.smallTablet} {
    padding-left: ${({ theme }) => theme.spacing.sm};
  }

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
    padding: ${({ theme }) => `${theme.spacing.xl} 0`};
    padding-left: ${rem(25)};
  }

  h2 {
    font-weight: 500;
  }

  blockquote {
    margin: ${({ theme }) => `0 -${theme.spacing.xs}`};
  }
`;

export const TextSectionContainer = styled(ComponentWrapper)`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const Subheading = styled(Text).attrs({
  styleType: 'subhead',
  ext: true,
})`
  text-transform: uppercase;
  letter-spacing: ${rem(3)};
  font-size: ${rem(14)};
  color: ${({ theme, onBlack }) =>
    onBlack ? theme.colors.lightIcon : theme.colors.captionText};
  font-weight: 500;
  padding-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const ComponentHeadline = styled(Text).attrs({
  as: 'h2',
  ext: true,
})`
  text-align: left;
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  ${({ theme }) => theme.media.tablet} {
    margin-bottom: ${({ theme }) => theme.spacing.rg};
  }
`;

export const HeroTitle = styled(Text).attrs({
  as: 'h1',
  ext: true,
})`
  font-size: ${rem(38)};
  line-height: ${rem(42)};
  word-wrap: nowrap;

  ${({ theme }) => theme.media.smallTablet} {
    font-size: ${rem(60)};
    line-height: ${rem(52)};
    letter-spacing: ${rem(0.3)};
  }

  ${({ theme }) => theme.media.giant} {
    font-size: ${rem(90)};
    letter-spacing: -${rem(3.5)};
    line-height: ${rem(94)};
  }
`;

export const HeroSubheading = styled(Text)`
  font-weight: 400;
  font-size: ${rem(18)};
  line-height: ${rem(26)};
  letter-spacing: ${rem(0.1)};

  ${({ theme }) => theme.media.smallTablet} {
    font-size: ${rem(26)};
    line-height: ${rem(32)};
    letter-spacing: ${rem(0.1)};
  }

  ${({ theme }) => theme.media.tablet} {
    white-space: nowrap;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: ${rem(30)};
    line-height: ${rem(38)};
    letter-spacing: -${rem(0.2)};
  }
`;

export const GreyWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.backgroundLight};
`;
