import styled from 'styled-components';
import { Flex, Text } from '@strikelabs/luna';
import { rem } from 'polished';

export const Column = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    padding: ${({ theme, left }) =>
      left ? `0 ${theme.spacing.lg} 0 0` : `0 0 0 ${theme.spacing.lg}`};
    margin-bottom: 0;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: ${rem(260)};
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;

  ${({ theme }) => theme.media.smallTablet} {
    height: ${rem(379)};
  }
`;

export const LinkTitle = styled(Text).attrs({ as: 'h2', ext: true })`
  ${({ theme }) => theme.media.desktop} {
    font-size: ${rem(30)};
    line-height: ${rem(40)};
  }
`;
